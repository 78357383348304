<template>
    <!-- <div class="rounded overflow-hidden shadow-lg scale-100 hover:scale-110 transition duration-500 ease-in-out">
        <div class="relative">
            <a :href="'https://' + item.url" target="_blank">
                <img class="object-contain overflow-hidden" :src="require('../images/' + item.image)">
                <div class="hover:bg-transparent transition duration-300 absolute bottom-0 top-0 right-0 left-0  opacity-25"></div>
            </a>
            <div class="px-6 py-4 bg-white">
                <a :href="item.url" target="_blank" class="font-semibold underline text-2xl inline-block hover:text-indigo-600 transition duration-500 ease-in-out">
                    {{ item.title }}
                </a>
                <p class="text-gray-500 text-md">{{ item.description }}</p>
            </div>
        </div>
    </div> -->
    <div class="flex items-center">
        <div class="rounded overflow-hidden shadow-lg scale-100 hover:scale-110 transition duration-500 ease-in-out">
            <div class="">
                <a :href="item.url" target="_blank">
                    <div class=""></div>
                    <img class="w-full h-80 object-cover hover:opacity-50" :src="require('../images/' + item.image)" loading="lazy">
                </a>
                <div class="p-6 bg-white">
                    <div class="flex flex-col items-center">
                        <a :href="item.url" target="_blank" class="font-semibold underline text-2xl pb-4 inline-block hover:text-indigo-600 transition duration-500 ease-in-out">
                            <h1>{{ item.title }}</h1>
                        </a>
                        <p class="text-gray-500 text-md">{{ item.description }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props:['item'],
    data(){
        return{
        }
    }
}
</script>