<template>
  <div class="flex justify-center text-4xl pt-24">
    <h1 class="text-5xl font-bold text-pink-400">Eleni & Lars 2025</h1>
  </div>  
    
  <div class="flex justify-center p-5">
    <img src="../images/elenilars.png">
  </div>  
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: { } 
}
</script>
