<template>
  <div class="flex flex-col bg-pink-200 min-h-screen font-Nunito">
    <SiteNavigation class="m-5"/>
    <router-view/>
  </div>
</template>

<script>
import SiteNavigation from './components/SiteNavigation.vue';
export default{
  components:{SiteNavigation}
}
</script>