import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/HomeView.vue'
import Accomedation from '../views/AccomedationView.vue'
import Activities from '../views/ActivitiesView.vue'
import ActivityComponent from '../components/ActivityComponent.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path:'/accomedation',
    name: 'accomedation',
    component: Accomedation
  },
  {
    path:'/activities',
    name: 'activities',
    component: Activities
  },
  {
    path: '/activityComponent',
    name: 'activityComponent',
    component: ActivityComponent,
    props: true
  },
  {

  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  document.title = "Eleni & Lars"
  next()
})

export default router
