<template>
  <div class="">
    <h1 class="flex justify-center p-5 text-3xl xl:text-5xl text-pink-400">ACTIVITIES</h1>
    <div class="grid grid-cols-1 xl:grid-cols-4 gap-10 p-8 xl:p-16">
      <div v-for="item in activities" :key="item.id">
        <ActivityComponent :item="item"/>
      </div>
    </div>
  </div>
</template>

<script>
import jsonData from '../../data/data.json'
import ActivityComponent from '../components/ActivityComponent.vue'

export default {
    name: 'Activities',
    data() {
        return {
            activities: jsonData.activities
        };
    },
    components: { ActivityComponent }
}
</script>
