<template>
  <div class="flex justify-center items-center">
    <div class="text-4xl p-5">
      <h1 class="">ACCOMEDATION</h1>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Accomedation',
  components: { } 
}
</script>
